import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Course from './pages/Course';
import CreateCourse from './pages/CreateCourse';
import Courses from './pages/Courses';
import Profile from './pages/Profile';
import JoinWaitlist from './pages/JoinWaitlist';
import AdminWaitlist from './pages/AdminWaitlist';
import PrivateRoute from './components/PrivateRoute';
import useAdmin from './hooks/useAdmin';
import PasswordReset from './pages/PasswordReset';
import Settings from './pages/Settings';

function App() {
  const [user, setUser] = useState(null);
  const { isAdmin, loading } = useAdmin();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Navbar user={user} isAdmin={isAdmin} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/join-waitlist" element={<JoinWaitlist />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route 
          path="/courses" 
          element={
            <PrivateRoute>
              <Courses />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/course/:courseId" 
          element={
            <PrivateRoute>
              <Course />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/create-course" 
          element={
            <PrivateRoute>
              <CreateCourse />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/profile" 
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/admin/waitlist" 
          element={
            <PrivateRoute>
              {isAdmin ? <AdminWaitlist /> : <Navigate to="/" />}
            </PrivateRoute>
          } 
        />
        <Route 
          path="/settings" 
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          } 
        />
      </Routes>
    </div>
  );
}

export default App;
