import React, { useState, useCallback, useEffect } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';

const AssessmentHierarchy = ({ topics, onSelectionChange, quizResults = [] }) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [expandedTopics, setExpandedTopics] = useState(() => {
    const initialExpanded = {};
    topics.forEach((_, index) => {
      initialExpanded[index] = true;
    });
    return initialExpanded;
  });

  useEffect(() => {
    setExpandedTopics(prev => {
      const newExpanded = { ...prev };
      topics.forEach((_, index) => {
        newExpanded[index] = true;
      });
      return newExpanded;
    });
  }, [topics]);

  const getSelectedContent = useCallback((topics, selection) => {
    const selectedStructure = {};

    topics.forEach((topic) => {
      // Only include topics that have selected content
      const hasSelectedContent = selection[topic.id]?.content &&
        Object.values(selection[topic.id].content).some(item => item.isSelected);

      if (hasSelectedContent) {
        selectedStructure[topic.id] = {
          contents: Object.entries(selection[topic.id].content)
            .filter(([_, item]) => item.isSelected)
            .map(([contentId, _]) => contentId)
        };
      }
    });

    console.log('Selected content structure:', selectedStructure);
    return selectedStructure;
  }, []);

  const toggleTopic = (topicIndex) => {
    setExpandedTopics(prev => ({
      ...prev,
      [topicIndex]: !prev[topicIndex]
    }));
  };

  const handleSelection = (topic, day, content) => {
    setSelectedItems(prev => {
      let newSelection = { ...prev };
      
      if (!day && !content) {
        // Handle topic-level selection
        const isTopicSelected = !prev[topic.id]?.isSelected;
        
        // Update topic selection
        newSelection[topic.id] = {
          isSelected: isTopicSelected,
          content: {}
        };

        // If topic is selected/deselected, update all its content accordingly
        topic.days.forEach(day => {
          day.content.forEach(content => {
            if (!newSelection[topic.id].content) {
              newSelection[topic.id].content = {};
            }
            newSelection[topic.id].content[content.id] = {
              isSelected: isTopicSelected,  // Match topic's selection state
              dayId: day.id
            };
          });
        });
      } else {
        // Handle content-level selection
        if (!newSelection[topic.id]) {
          newSelection[topic.id] = {
            isSelected: false,
            content: {}
          };
        }

        if (!newSelection[topic.id].content) {
          newSelection[topic.id].content = {};
        }

        // Toggle content selection
        const isContentSelected = !newSelection[topic.id].content[content.id]?.isSelected;
        newSelection[topic.id].content[content.id] = {
          isSelected: isContentSelected,
          dayId: day.id
        };

        // Check if ALL content under this topic is now selected
        const allContentSelected = topic.days.every(d =>
          d.content.every(c => 
            newSelection[topic.id].content[c.id]?.isSelected
          )
        );

        // Update topic selection based on ALL content being selected
        newSelection[topic.id].isSelected = allContentSelected;
      }

      setTimeout(() => {
        const structuredContent = getSelectedContent(topics, newSelection);
        onSelectionChange(newSelection, structuredContent);
      }, 0);
      
      return newSelection;
    });
  };

  const PerformanceIndicator = () => (
    <div className="flex gap-1 ml-auto">
      {[1, 2, 3].map((box, index) => (
        <div 
          key={index}
          className="w-4 h-4 bg-gray-200 border border-gray-300 rounded"
          title={`Quiz attempt ${box}`}
        />
      ))}
    </div>
  );

  const getScoreColor = (score) => {
    switch(score) {
      case 1: return 'bg-red-500';
      case 2: return 'bg-orange-500';
      case 3: return 'bg-green-500';
      default: return 'bg-gray-200';
    }
  };

  const renderScoreBoxes = (contentId) => {
    // console.log('Rendering scores for content:', contentId);
    
    // Get last 3 scores for this content
    const scores = quizResults
      .filter(result => {
        return result.contentScores && 
               result.contentScores[contentId] &&
               result.contentScores[contentId].score;
      })
      .map(result => ({
        score: result.contentScores[contentId].score,
        timestamp: result.timestamp,
        reason: result.contentScores[contentId].reason
      }))
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, 3)
      .map(item => item.score);

    return (
      <div className="flex gap-1">
        {[0, 1, 2].map((index) => (
          <div
            key={index}
            className={`w-4 h-4 rounded ${getScoreColor(scores[index])}`}
            title={scores[index] ? `Score: ${scores[index]}` : 'No score yet'}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="assessment-hierarchy">
      {topics.map((topic, topicIndex) => (
        <div key={topicIndex} className="topic-item mb-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={selectedItems[topic.id]?.isSelected || false}
              onChange={() => handleSelection(topic)}
              className="mr-2"
            />
            <span 
              className="cursor-pointer flex items-center flex-1"
              onClick={() => toggleTopic(topicIndex)}
            >
              {expandedTopics[topicIndex] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
              <span className="font-semibold ml-1 flex-1">{topic.title}</span>
              <PerformanceIndicator />
            </span>
          </div>
          {expandedTopics[topicIndex] && (
            <div className="ml-6 mt-2">
              {topic.days.map((day, dayIndex) => (
                <div key={dayIndex} className="day-item mb-2">
                  {day.content.map((content, contentIndex) => (
                    <div key={contentIndex} className="content-item flex items-center mb-1">
                      <input
                        type="checkbox"
                        checked={
                          selectedItems[topic.id]?.isSelected || 
                          selectedItems[topic.id]?.content[content.id]?.isSelected || 
                          false
                        }
                        onChange={() => handleSelection(topic, day, content)}
                        className="mr-2"
                      />
                      <span className="flex-1">{content.title}</span>
                      {renderScoreBoxes(content.id)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AssessmentHierarchy;
