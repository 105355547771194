import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../firebase';
import useAdmin from '../hooks/useAdmin';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';
import { Navigate } from 'react-router-dom';

const AdminWaitlist = () => {
  const { isAdmin, loading } = useAdmin();
  const [waitlist, setWaitlist] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWaitlist = async () => {
      if (!isAdmin) return;
      
      try {
        const waitlistCollection = collection(db, 'waitlist');
        const waitlistSnapshot = await getDocs(waitlistCollection);
        const waitlistData = waitlistSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setWaitlist(waitlistData);
      } catch (err) {
        console.error('Error fetching waitlist:', err);
        setError('Failed to fetch waitlist. Please try again.');
      }
    };

    if (!loading && isAdmin) {
      fetchWaitlist();
    }
  }, [isAdmin, loading]);

  const activateUser = async (waitlistUser) => {
        try {

        // Get the current user's ID token
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) {
            throw new Error('User not authenticated');
        }
        const functions = getFunctions();
        console.log('calling function createUser');
        const createUser = httpsCallable(functions, 'createUser');
        
        // Generate a random temporary password
        const tempPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).toUpperCase().slice(-8) + '!1';
        
        // // Call the Cloud Function to create the user
        const result = await createUser({
            email: waitlistUser.email,
            password: tempPassword,
            firstName: waitlistUser.firstName,
            lastName: waitlistUser.lastName
        });

        if (result.data.success) {
        //     // THIS IS DONE ON THE SERVER
        //     // Remove user from waitlist
        //     // await deleteDoc(doc(db, 'waitlist', waitlistUser.id));

        //     // // Send password reset email
        //     // await sendPasswordResetEmail(auth, waitlistUser.email);

        //     // // Update local state
        //     // setWaitlist(waitlist.filter(item => item.id !== waitlistUser.id));

        //     // alert(`User ${waitlistUser.email} has been activated. A password reset email has been sent to their address.`);
        } else {
            throw new Error(result.data.error || 'Failed to create user');
        }
        } catch (error) {
        console.error('Error activating user:', error);
        alert(`Error activating user: ${error.message}`);
        }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (waitlist.length === 0) {
    return <p>No users in the waitlist.</p>;
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Waitlist Management</h2>
      <ul>
        {waitlist.map(user => (
          <li key={user.id} className="mb-4 p-4 border rounded">
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Name:</strong> {user.firstName} {user.lastName}</p>
            <p><strong>Joined:</strong> {new Date(user.createdAt).toLocaleString()}</p>
            <button
              onClick={() => activateUser(user)}
              className="mt-2 bg-green-500 text-white px-4 py-2 rounded"
            >
              Activate User
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminWaitlist;
