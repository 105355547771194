import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { confirmPasswordReset, verifyPasswordResetCode, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState('');
  const [showRequestNewLink, setShowRequestNewLink] = useState(false);

  useEffect(() => {
    console.log('PasswordReset component mounted');
    const oobCode = searchParams.get('oobCode');
    console.log('oobCode:', oobCode);
    if (oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then((email) => {
          console.log('Email verified:', email);
          setIsValid(true);
          setEmail(email);
        })
        .catch((error) => {
          console.error('Error verifying reset code:', error);
          setMessage('Invalid or expired reset link. Please request a new one.');
          setShowRequestNewLink(true);
        });
    } else {
      console.log('No oobCode found');
      setMessage('No reset code found. Please request a new password reset link.');
      setShowRequestNewLink(true);
    }
  }, [searchParams]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    if (password.length < 6) {
      setMessage('Password should be at least 6 characters long');
      return;
    }
    try {
      const oobCode = searchParams.get('oobCode');
      await confirmPasswordReset(auth, oobCode, password);
      setMessage('Password reset successful. You can now log in with your new password.');
      setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      console.error('Error resetting password:', error);
    }
  };

  const handleRequestNewLink = async () => {
    if (!email) {
      setMessage('Please enter your email address.');
      return;
    }
    try {
      console.log('Attempting to send password reset email to:', email);
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
      setMessage('A new password reset link has been sent to your email.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setMessage(`Error: ${error.message}`);
    }
  };

  if (!isValid) {
    return (
      <div className="container mx-auto mt-8">
        <h2 className="text-2xl font-bold mb-4">Password Reset</h2>
        {message && <p className="mb-4">{message}</p>}
        {showRequestNewLink && (
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-3 py-2 border rounded mb-4"
            />
            <button
              onClick={handleRequestNewLink}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Request New Reset Link
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="text-2xl font-bold mb-4">Reset Your Password</h2>
      <p className="mb-4">Enter a new password for {email}</p>
      <form onSubmit={handleSubmit} className="max-w-md">
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">New Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-2">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          Reset Password
        </button>
      </form>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
};

export default PasswordReset;
