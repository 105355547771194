/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { ref, getStorage, uploadBytes, getDownloadURL, getMetadata } from 'firebase/storage';
import { db, storage } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { Edit2, Trash2, PlusCircle, ChevronDown, ChevronUp, Save, FileText, Image, Mic, Calendar, Headphones, X, Volume2, Volume } from 'react-feather'; // Update import
import EditCourseModal from '../components/EditCourseModal';
import { format, parseISO, startOfDay } from 'date-fns';
import axios from 'axios';
import AudioPlayer from '../components/AudioPlayer';
import AssessmentHierarchy from '../components/AssessmentHierarchy';
import QuizChatbox from '../components/QuizChatbox';
import { v4 as uuidv4 } from 'uuid';

// Add this utility function at the top of your file, outside the Course component
const ensureCorrectDayStructure = (day) => {
  return {
    date: day.date || new Date().toISOString(),
    content: Array.isArray(day.content) ? day.content : [],
    lecture: day.lecture || { summary: '', timestamp: null }
  };
};

// Add this new function
const readTopic = (course, topicIndex, dayIndex, handleTextToSpeechV4) => {
  const day = course.topics[topicIndex].days[dayIndex];
  const textToSpeak = day.content
    .map(
      (item) =>
        `${item.title}. ${item.type === 'text' ? item.data : item.data.summary}`
    )
    .join(' ');
  
  const audioKey = `course_${course.id}_topic_${topicIndex}_day_${dayIndex}`;
  handleTextToSpeechV4(textToSpeak, audioKey, "en-US-BrianMultilingualNeural");
};

function Course() {
  const { courseId } = useParams();
  const [activeTab, setActiveTab] = useState('outline'); // Set default to 'outline'
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingSummary, setEditingSummary] = useState(false);
  const [editingObjective, setEditingObjective] = useState(null);
  const [newObjective, setNewObjective] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingTopic, setEditingTopic] = useState(null);
  const [newTopic, setNewTopic] = useState('');
  const [expandedTopics, setExpandedTopics] = useState({});
  const [expandedDays, setExpandedDays] = useState({});
  const [editingDay, setEditingDay] = useState(null);
  const [newContent, setNewContent] = useState('');
  const [addingContentTo, setAddingContentTo] = useState(null);
  const [editingContent, setEditingContent] = useState(null);
  const [editingContentData, setEditingContentData] = useState('');
  const [editingContentTitle, setEditingContentTitle] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [processingDay, setProcessingDay] = useState(null);
  const [uploadCancelled, setUploadCancelled] = useState(false);
  const [audioControls, setAudioControls] = useState(null);
  const [audioState, setAudioState] = useState({});
  const [audioPlayerUrl, setAudioPlayerUrl] = useState(null);
  const [processingLecture, setProcessingLecture] = useState(null);
  const [selectedAssessmentItems, setSelectedAssessmentItems] = useState({});
  const [showQuizChatbox, setShowQuizChatbox] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);
  const [quizResults, setQuizResults] = useState([]);

  // Add this function near the top of the component
  const updateCourse = async (updates) => {
    try {
      const courseRef = doc(db, 'courses', courseId);
      await updateDoc(courseRef, {
        ...updates,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error updating course:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const courseDoc = await getDoc(doc(db, 'courses', courseId));
        if (courseDoc.exists()) {
          const courseData = courseDoc.data();
          // console.log('Fetched course data:', courseData);

          // Ensure topics is an array and each day has the correct structure
          const topics = Array.isArray(courseData.topics) ? courseData.topics.map(topic => ({
            ...topic,
            days: Array.isArray(topic.days) ? topic.days.map(day => ({
              ...day,
              content: Array.isArray(day.content) ? day.content : [],
              lecture: day.lecture || { summary: '', timestamp: null }
            })) : []
          })) : [];

          // Initialize expandedTopics and expandedDays
          const topicsExpanded = {};
          const daysExpanded = {};
          
          topics.forEach((topic, topicIndex) => {
            topicsExpanded[topicIndex] = true;
            topic.days.forEach((_, dayIndex) => {
              daysExpanded[`${topicIndex}-${dayIndex}`] = true;
            });
          });

          setCourse(prevCourse => {
            const updatedCourse = {
              id: courseDoc.id,
              ...courseData,
              topics: topics,
              title: courseData.title || 'Untitled Course',
              description: courseData.description || 'No description available',
              outline: courseData.outline || { summary: '', objectives: [] },
              assessment: courseData.assessment || 'No assessment details available'
            };
            // console.log('Updated course:', updatedCourse);
            // console.log('Course outline:', updatedCourse.outline);
            // console.log('Course outline summary:', updatedCourse.outline.summary);
            
            // Set active tab based on course content
            if (updatedCourse.outline && updatedCourse.outline.summary) {
              setActiveTab('notes');
            } else {
              setActiveTab('outline');
            }
            
            return updatedCourse;
          });

          setExpandedTopics(topicsExpanded);
          setExpandedDays(daysExpanded);
        } else {
          console.log('No such course!');
          setCourse({
            id: courseId,
            title: 'New Course',
            description: 'Add a description for your new course',
            topics: [],
            outline: { summary: '', objectives: [] },
            assessment: 'Add assessment details'
          });
          setActiveTab('outline');
        }
      } catch (error) {
        console.error('Error fetching course:', error);
        setActiveTab('outline');
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [courseId]);

  // New useEffect to log activeTab changes
  useEffect(() => {
    console.log('Active tab changed:', activeTab);
  }, [activeTab]);

  const handleSummaryEdit = (newSummary) => {
    updateCourse({ 'outline.summary': newSummary });
    setEditingSummary(false);
  };

  const handleObjectiveEdit = (index, newObjective) => {
    const updatedObjectives = [...course.outline.objectives];
    updatedObjectives[index] = newObjective;
    updateCourse({ 'outline.objectives': updatedObjectives });
    setEditingObjective(null);
  };

  const handleObjectiveDelete = (index) => {
    const updatedObjectives = course.outline.objectives.filter((_, i) => i !== index);
    updateCourse({ 'outline.objectives': updatedObjectives });
    setCourse(prevCourse => ({
      ...prevCourse,
      outline: {
        ...prevCourse.outline,
        objectives: updatedObjectives,
      },
    }));
  };

  const handleObjectiveAdd = () => {
    if (newObjective.trim()) {
      const updatedObjectives = [...(course.outline?.objectives || []), newObjective.trim()];
      updateCourse({ 'outline.objectives': updatedObjectives });
      setCourse(prevCourse => ({
        ...prevCourse,
        outline: {
          ...prevCourse.outline,
          objectives: updatedObjectives,
        },
      }));
      setNewObjective('');
    }
  };

  const handleCourseEdit = async (updatedCourse) => {
    try {
      await updateCourse(updatedCourse);
      // Update local state after successful Firestore update
      setCourse(prevCourse => ({
        ...prevCourse,
        ...updatedCourse
      }));
    } catch (error) {
      console.error('Error updating course:', error);
      alert('Failed to update course. Please try again.');
    }
  };

  const handleTopicAdd = async (e) => {
    if (e && e.key === 'Enter') {
      e.preventDefault();
    } else if (e && e.key !== 'Enter') {
      return;
    }

    if (!newTopic.trim()) return;
    
    try {
      const updatedTopics = [...course.topics];
      updatedTopics.push({
        id: uuidv4(),
        title: newTopic,
        days: []  // Initialize with empty days array instead of creating a default day
      });

      // Update database
      await updateCourse({ topics: updatedTopics });
      
      // Update local state
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));
      
      // Clear the input
      setNewTopic('');
      
      // Expand the newly created topic
      setExpandedTopics(prev => ({
        ...prev,
        [updatedTopics.length - 1]: true  // Expand the new topic
      }));
    } catch (error) {
      console.error('Error adding topic:', error);
      alert('Failed to add topic. Please try again.');
    }
  };

  const handleDayAdd = async (topicIndex) => {
    try {
      const updatedTopics = [...course.topics];
      updatedTopics[topicIndex].days.push({
        id: uuidv4(),
        date: new Date().toISOString(),
        content: [],
        lecture: { summary: '', timestamp: null }
      });

      // Update database
      await updateCourse({ topics: updatedTopics });
      
      // Update local state
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));

      // Expand the newly added day
      const dayIndex = updatedTopics[topicIndex].days.length - 1;
      setExpandedDays(prev => ({
        ...prev,
        [`${topicIndex}-${dayIndex}`]: true
      }));
    } catch (error) {
      console.error('Error adding day:', error);
      alert('Failed to add day. Please try again.');
    }
  };

  const handleDayEdit = (topicIndex, dayIndex, newDate) => {
    const updatedTopics = [...course.topics];

    // Parse the new date string to a Date object
    const selectedDate = parseISO(newDate);

    // Set the time to the start of the day (midnight)
    const startOfSelectedDay = startOfDay(selectedDate);

    // Store as ISO string
    updatedTopics[topicIndex].days[dayIndex].date = startOfSelectedDay.toISOString();
    updateCourse({ topics: updatedTopics });
    setEditingDay(null);
  };

  const handleContentAdd = async (topicIndex, dayIndex, type = 'text') => {
    if (!addingContentTo) return;

    const contentId = uuidv4(); // Generate ID first
    console.log('Generated content ID:', contentId); // Add logging

    const updatedTopics = [...course.topics];
    updatedTopics[topicIndex].days[dayIndex].content.push({
      id: contentId,  // Just use one ID field
      title: newTitle || 'New Content',
      type: type,
      data: type === 'text' ? newContent : { summary: newContent },
      timestamp: new Date().toISOString()
    });

    try {
      await updateCourse({ topics: updatedTopics });
      console.log('Content added with ID:', contentId); // Add logging
      
      // Update local state
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));
      
      setAddingContentTo(null);
      setNewContent('');
      setNewTitle('');
    } catch (error) {
      console.error('Error adding content:', error);
      alert('Failed to add content. Please try again.');
    }
  };

  const handleContentKeyDown = (e, topicIndex, dayIndex, contentIndex = null) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      if (contentIndex !== null) {
        // For editing existing content
        handleContentEdit(topicIndex, dayIndex, contentIndex, newContent, newTitle);
      } else {
        // For adding new content
        handleContentAdd(topicIndex, dayIndex);
      }
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setNewTitle('');
      setNewContent('');
      setAddingContentTo(null);
    }
  };

  const toggleTopicExpansion = (index) => {
    setExpandedTopics(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const toggleDayExpansion = (topicIndex, dayIndex) => {
    setExpandedDays(prev => ({ ...prev, [`${topicIndex}-${dayIndex}`]: !prev[`${topicIndex}-${dayIndex}`] }));
  };

  const handleTopicEdit = (index, newTitle) => {
    const updatedTopics = [...course.topics];
    updatedTopics[index].title = newTitle;
    updateCourse({ topics: updatedTopics });
    setEditingTopic(null);
  };

  const handleTopicDelete = async (index) => {
    const topicToDelete = course.topics[index];
    
    // Check if the topic has any days with content
    const hasDaysWithContent = topicToDelete.days && topicToDelete.days.some(day => 
      day.content && day.content.length > 0
    );
    
    if (hasDaysWithContent) {
      const confirmDelete = window.confirm(
        "This topic contains days with content. Deleting this topic will permanently remove all associated days and their content. Are you sure you want to proceed?"
      );
      if (!confirmDelete) {
        return;
      }
    }

    try {
      const updatedTopics = course.topics.filter((_, i) => i !== index);
      await updateCourse({ topics: updatedTopics });
      
      // Update local state after successful deletion
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));
    } catch (error) {
      console.error('Error deleting topic:', error);
      alert('Failed to delete topic. Please try again.');
    }
  };

  const handleContentEdit = (topicIndex, dayIndex, contentIndex) => {
    const content = course.topics[topicIndex].days[dayIndex].content[contentIndex];
    setEditingContent(`${topicIndex}-${dayIndex}-${contentIndex}`);
    setEditingContentTitle(content.title);
    setEditingContentData(content.type === 'text' ? content.data : content.data.summary);
  };

  const saveContentEdit = (topicIndex, dayIndex, contentIndex) => {
    const updatedTopics = [...course.topics];
    const content = updatedTopics[topicIndex].days[dayIndex].content[contentIndex];
    content.title = editingContentTitle;
    if (content.type === 'text') {
      content.data = editingContentData;
    } else {
      content.data.summary = editingContentData;
    }
    updateCourse({ topics: updatedTopics });
    setEditingContent(null);
    setEditingContentTitle('');
    setEditingContentData('');
  };

  const handleContentDelete = async (topicIndex, dayIndex, contentIndex) => {
    try {
      const updatedTopics = [...course.topics];
      updatedTopics[topicIndex].days[dayIndex].content.splice(contentIndex, 1);
      
      // Update database
      await updateCourse({ topics: updatedTopics });
      
      // Update local state
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));
    } catch (error) {
      console.error('Error deleting content:', error);
      alert('Failed to delete content. Please try again.');
    }
  };

  const handleDayDelete = async (topicIndex, dayIndex) => {
    const day = course.topics[topicIndex].days[dayIndex];

    if (day.content && day.content.length > 0) {
      const confirmDelete = window.confirm(
        `This day contains ${day.content.length} content item(s). Deleting this day will permanently remove all associated content. Are you sure you want to proceed?`
      );
      if (!confirmDelete) {
        return;
      }
    }

    try {
      const updatedTopics = [...course.topics];
      updatedTopics[topicIndex].days.splice(dayIndex, 1);
      
      // Update database
      await updateCourse({ topics: updatedTopics });
      
      // Update local state
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));
    } catch (error) {
      console.error('Error deleting day:', error);
      alert('Failed to delete day. Please try again.');
    }
  };

  const handleImageUpload = async (topicIndex, dayIndex) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      try {
        // Log start of upload process
        console.log('Starting image upload process');

        const contentId = uuidv4();
        const fileName = `${contentId}_${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
        const storageRef = ref(storage, `images/${fileName}`);
        
        // Log storage upload
        console.log('Uploading to Firebase Storage');
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        console.log('Image uploaded successfully, URL obtained');

        // Convert image to base64 and perform OCR
        setProcessingDay(`${topicIndex}-${dayIndex}`);
        const reader = new FileReader();
        
        const imageText = await new Promise((resolve, reject) => {
          reader.onloadend = async () => {
            try {
              const base64data = reader.result.split(',')[1];
              
              // OCR with Google Vision API
              console.log('Starting OCR with Vision API');
              const visionResponse = await axios.post(
                `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                {
                  requests: [
                    {
                      image: { content: base64data },
                      features: [{ type: 'TEXT_DETECTION' }],
                    },
                  ],
                }
              );

              const detectedText = visionResponse.data.responses[0]?.textAnnotations?.[0]?.description || 'No text detected';
              console.log('OCR completed:', detectedText);
              resolve(detectedText);
            } catch (error) {
              reject(error);
            }
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
        
        if (uploadCancelled) {
          setUploadCancelled(false);
          setProcessingDay(null);
          return;
        }

        // Generate title and summary with OpenAI
        console.log('Calling OpenAI API for title and summary');
        const openaiResponse = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'system',
                content: 'You are a helpful assistant that generates titles and summaries for text content.'
              },
              {
                role: 'user',
                content: `Please provide a short title and summary for this text. Respond in JSON format with "title" and "summary" fields: ${imageText}`
              }
            ]
          },
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('OpenAI API response received');

        let title; let summary;
        try {
          const cleanJsonString = openaiResponse.data.choices[0].message.content
            .replace(/```json\n?/, '')
            .replace(/\n?```/, '')
            .trim();
          const parsedResponse = JSON.parse(cleanJsonString);
          title = parsedResponse.title;
          summary = parsedResponse.summary;
        } catch (error) {
          console.error('Error parsing OpenAI response:', error);
          title = 'Generated Title';
          summary = openaiResponse.data.choices[0].message.content;
        }

        // Check if upload was cancelled
        if (uploadCancelled) {
          console.log('Upload cancelled');
          return;
        }

        // Add the new content to the course
        const updatedTopics = [...course.topics];
        updatedTopics[topicIndex].days[dayIndex].content.push({
          type: 'image',
          title,
          data: {
            summary,
            url,
          },
          timestamp: new Date().toISOString(),
        });
        updateCourse({ topics: updatedTopics });
      } catch (error) {
        console.error('Error processing image:', error);
        alert('Error processing image. Please try again.');
      } finally {
        setProcessingDay(null);
        setUploadCancelled(false);
      }
    };

    input.click();
  };

  const handleContentTitleEdit = (topicIndex, dayIndex, contentIndex, newTitle) => {
    const updatedTopics = [...course.topics];
    updatedTopics[topicIndex].days[dayIndex].content[contentIndex].title = newTitle;
    updateCourse({ topics: updatedTopics });
    setEditingContentTitle(null);
  };

  const handleTextToSpeechV4 = async (textToSpeak, audioKey, voiceName = 'en-US-Neural2', forceGenerate = false) => {
    // console.log('handleTextToSpeechV4 called');
    // console.log('Text to speak length:', textToSpeak.length);
    // console.log('Voice name:', voiceName);
  
    const audioPath = `audio/${audioKey}.mp3`;
    const audioRef = ref(storage, audioPath);

    try {
      const functions = getFunctions();
      const textToSpeechV4 = httpsCallable(functions, 'textToSpeechV4');
      const result = await textToSpeechV4({ text: textToSpeak, voiceName, audioKey, forceGenerate });

      // console.log('Function call successful', result);

      if (!result.data || !result.data.audioPath) {
        throw new Error('Invalid response from text-to-speech function');
      }

      // const { audioPath, contentType } = result.data;
      // const audioUrl = await getDownloadURL(ref(storage, audioPath));
      // playAudio(audioUrl, audioKey);
    } catch (error) {
      console.error('Error in textToSpeechV4:', error);
      alert(`Failed to generate speech: ${error.message}`);
    }
  };

  // Helper function to play audio and manage state
  const playAudio = (audioUrl, audioKey) => {
    const audio = new Audio(audioUrl);
  
    audio.onplay = () => {
      setAudioState(prevState => ({
        ...prevState,
        [audioKey]: { audio, isPlaying: true }
      }));
    };
  
    audio.onpause = () => {
      setAudioState(prevState => ({
        ...prevState,
        [audioKey]: { ...prevState[audioKey], isPlaying: false }
      }));
    };
  
    audio.onended = () => {
      setAudioState(prevState => ({
        ...prevState,
        [audioKey]: { ...prevState[audioKey], isPlaying: false }
      }));
    };
  
    setAudioState(prevState => ({
      ...prevState,
      [audioKey]: { audio, isPlaying: true }
    }));
  
    audio.play();
  };

  const handleAudioUpload = async (topicIndex, dayIndex) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'audio/*';
    
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;

      try {
        const contentId = uuidv4();
        const fileName = `${contentId}_${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
        const storageRef = ref(storage, `audio/${fileName}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);

        // Transcribe audio
        setProcessingDay(`${topicIndex}-${dayIndex}`);
        const functions = getFunctions();
        const transcribeAudio = httpsCallable(functions, 'transcribeAudio');
        const result = await transcribeAudio({ audioPath: `audio/${fileName}` });

        if (uploadCancelled) {
          setUploadCancelled(false);
          setProcessingDay(null);
          return;
        }

        // Generate title and summary with OpenAI
        const openaiResponse = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: 'gpt-3.5-turbo',
            messages: [
              {
                role: 'system',
                content: 'You are a helpful assistant that generates titles and summaries for transcribed audio content.'
              },
              {
                role: 'user',
                content: `Please provide a short title and summary for this transcribed audio. Respond in JSON format with "title" and "summary" fields: ${result.data.transcription}`
              }
            ]
          },
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const { title, summary } = JSON.parse(openaiResponse.data.choices[0].message.content);

        const updatedTopics = [...course.topics];
        updatedTopics[topicIndex].days[dayIndex].content.push({
          id: contentId,
          title: title || file.name,
          type: 'audio',
          data: { 
            url, 
            fileName,  // Store the fileName for future reference
            summary: summary || 'No summary available',
            transcription: result.data.transcription || 'No transcription available'
          },
          timestamp: new Date().toISOString()
        });

        await updateCourse({ topics: updatedTopics });
        setProcessingDay(null);
      } catch (error) {
        console.error('Error uploading audio:', error);
        alert('Failed to upload audio');
        setProcessingDay(null);
      }
    };

    input.click();
  };

  const handleCancelUpload = () => {
    setUploadCancelled(true);
    setProcessingDay(null);
    setProcessingLecture(null);
  };

  const handleTextToSpeech = async (topicIndex, dayIndex) => {
    console.log('handleTextToSpeech called for topic', topicIndex, 'day', dayIndex);
    const day = course.topics[topicIndex].days[dayIndex];
    const textToSpeak = day.content
      .map(
        (item) =>
          `${item.title}. ${item.type === 'text' ? item.data : item.summary}`
      )
      .join(' ');
    console.log('Text to speak:', textToSpeak);

    try {
      console.log('Initializing Firebase Functions');
      const functions = getFunctions();
      console.log('Getting textToSpeechCallable function');
      const textToSpeechFunction = httpsCallable(functions, 'textToSpeechCallable');
      
      console.log('Calling textToSpeechCallable function');
      const result = await textToSpeechFunction({ text: textToSpeak });
      console.log('Function call successful', result);

      if (!result.data || !result.data.audioContent) {
        throw new Error('Invalid response from text-to-speech function');
      }

      console.log('Extracting audio content and type');
      const { audioContent, contentType } = result.data;
      console.log('Audio content length:', audioContent.length);
      console.log('Content type:', contentType);

      console.log('Converting base64 to Blob');
      const audioBlob = base64ToBlob(audioContent, contentType);
      console.log('Audio Blob size:', audioBlob.size);

      console.log('Creating object URL');
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log('Audio URL created:', audioUrl);

      console.log('Creating Audio object');
      const audio = new Audio(audioUrl);
      
      console.log('Playing audio');
      audio.play();

      audio.onended = () => {
        console.log('Audio playback ended');
        URL.revokeObjectURL(audioUrl);
        console.log('Object URL revoked');
      };
    } catch (error) {
      console.error('Error in text-to-speech:', error);
      console.error('Error name:', error.name);
      console.error('Error message:', error.message);
      if (error.code) {
        console.error('Error code:', error.code);
      }
      if (error.details) {
        console.error('Error details:', error.details);
      }
      if (error.stack) {
        console.error('Error stack:', error.stack);
      }
      alert(`Failed to generate speech: ${error.message}`);
    }
  };

  const handleClientSideTextToSpeech = async (topicIndex, dayIndex) => {
    console.log('handleClientSideTextToSpeech called for topic', topicIndex, 'day', dayIndex);
    const day = course.topics[topicIndex].days[dayIndex];
    const textToSpeak = day.content
      .map(
        (item) =>
          `${item.title}. ${item.type === 'text' ? item.data : item.summary}`
      )
      .join(' ');
    console.log('Text to speak:', textToSpeak);

    try {
      // Replace 'YOUR_AZURE_SPEECH_KEY' with your actual Azure Speech key
      const azureSpeechKey = '5ed8dffecf6f4d71af371588dea01b30';
      const azureUrl = 'https://centralus.tts.speech.microsoft.com/cognitiveservices/v1';

      const response = await axios({
        method: 'post',
        url: azureUrl,
        headers: {
          'Ocp-Apim-Subscription-Key': azureSpeechKey,
          'Content-Type': 'application/ssml+xml',
          'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3',
        },
        data: `<speak version='1.0' xml:lang='en-US'>
          <voice xml:lang='en-US' xml:gender='Female' name='en-US-JennyNeural'>
            ${textToSpeak}
          </voice>
        </speak>`,
        responseType: 'arraybuffer',
      });

      console.log('Azure API call successful');

      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);

      console.log('Creating Audio object');
      const audio = new Audio(audioUrl);
      
      console.log('Playing audio');
      audio.play();

      audio.onended = () => {
        console.log('Audio playback ended');
        URL.revokeObjectURL(audioUrl);
        console.log('Object URL revoked');
      };
    } catch (error) {
      console.error('Error in client-side text-to-speech:', error);
      console.error('Error name:', error.name);
      console.error('Error message:', error.message);
      if (error.response) {
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
      }
      alert(`Failed to generate speech: ${error.message}`);
    }
  };

  const handleCreateLecture = async (topicIndex, dayIndex = null) => {
    try {
      console.log('handleCreateLecture called with:', { topicIndex, dayIndex });

      const isTopic = dayIndex === null;

      // Generate a unique audioKey
      const audioKey = isTopic
        ? `course_${course.id}_topic_lecture_${topicIndex}`
        : `course_${course.id}_lecture_${topicIndex}_day_${dayIndex}`;
      const audioPath = `audio/${audioKey}.mp3`;
      const audioRef = ref(storage, audioPath);

      try {
        // Check if the audio file already exists
        await getMetadata(audioRef);
        
        const confirmMessage = isTopic
          ? 'A lecture for this topic already exists. Do you want to regenerate it?'
          : 'A lecture for this day already exists. Do you want to regenerate it?';
        
        const shouldRegenerate = window.confirm(confirmMessage);
        if (!shouldRegenerate) {
          console.log('User chose to keep existing lecture summary');
          return;
        }
      } catch (error) {
        // If the file doesn't exist, proceed with creating a new lecture summary
        if (error.code !== 'storage/object-not-found') {
          console.error('Error checking audio file:', error);
          throw error;
        }
      }

      // Now we're sure we're proceeding, so we can show the loading UI
      setProcessingLecture(isTopic ? `topic_${topicIndex}` : `${topicIndex}-${dayIndex}`);

      let textToSummarize;
      if (isTopic) {
        const topic = course.topics[topicIndex];
        textToSummarize = topic.days.flatMap(day => 
          day.content.map(item => `${item.title || 'Untitled'}. ${item.type === 'text' ? item.data : item.data.summary || ''}`)
        ).join(' ');
      } else {
        const day = course.topics[topicIndex].days[dayIndex];
        textToSummarize = day.content
          .map(item => `${item.title || 'Untitled'}. ${item.type === 'text' ? item.data : item.data.summary || ''}`)
          .join(' ');
      }

      console.log('Text to summarize:', textToSummarize);

      if (!textToSummarize.trim()) {
        alert(isTopic ? 'No content to summarize in this topic.' : 'No content to summarize.');
        setProcessingLecture(null);
        return;
      }

      const instruction = isTopic
        ? "Create an interesting talk for an individual student about the following material that their class notes. They would like to read about them in a clearer, and more engaging way. Think of your role as an expert educator: keep the details and make the notes more interesting and informative. Keep it academic, but personalized. No need for an intro, just get right into it."
        : "Create an interesting talk for an individual student about the following material that their class notes. They would like to read about them in a clearer, and more engaging way. Think of your role as an expert educator: keep the details and make the notes more interesting and informative. Keep it academic, but personalized. No need for an intro, just get right into it.";

      const functions = getFunctions();
      const textSummaryClaude = httpsCallable(functions, 'textSummaryClaude');
      const result = await textSummaryClaude({
        text: textToSummarize,
        instruction: instruction
      });
      
      console.log('Received summary from textSummaryClaude:', result.data.summary);

      // Update the course data with the new lecture summary
      const updatedTopics = [...course.topics];
      if (isTopic) {
        updatedTopics[topicIndex] = {
          ...updatedTopics[topicIndex],
          topicLecture: {
            summary: result.data.summary,
            timestamp: new Date().toISOString(),
          }
        };
      } else {
        updatedTopics[topicIndex].days[dayIndex] = {
          ...updatedTopics[topicIndex].days[dayIndex],
          lecture: {
            summary: result.data.summary,
            timestamp: new Date().toISOString(),
          }
        };
      }

      // Update the database
      await updateDoc(doc(db, 'courses', courseId), { topics: updatedTopics });

      // Update the local state
      setCourse(prevCourse => ({
        ...prevCourse,
        topics: updatedTopics
      }));

      console.log(isTopic ? 'Topic lecture summary updated successfully' : 'Lecture summary updated successfully');

      // Generate audio for the new lecture summary
      await handleTextToSpeechV4(result.data.summary, audioKey, "en-US-BrianMultilingualNeural", true);

      setProcessingLecture(null);
    } catch (error) {
      console.error('Error in handleCreateLecture:', error);
      alert(`Error: ${error.message}`);
      setProcessingLecture(null);
    }
  };

  // Helper function to convert base64 to Blob
  const base64ToBlob = (base64Data, contentType) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (
      let offset = 0;
      offset < byteCharacters.length;
      offset += sliceSize
    ) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  // Move the readLecture function inside the Course component
  const readLecture = async (course, topicIndex, dayIndex = null) => {
    const isTopic = dayIndex === null;
    
    let lecture;
    if (isTopic) {
      lecture = course.topics[topicIndex].topicLecture;
    } else {
      lecture = course.topics[topicIndex].days[dayIndex].lecture;
    }

    if (lecture && lecture.summary) {
      const audioKey = isTopic
        ? `course_${course.id}_topic_lecture_${topicIndex}`
        : `course_${course.id}_lecture_${topicIndex}_day_${dayIndex}`;
      const audioPath = `audio/${audioKey}.mp3`;
      const audioRef = ref(storage, audioPath);
      
      try {
        const audioUrl = await getDownloadURL(audioRef);
        setAudioPlayerUrl(audioUrl);
      } catch (error) {
        console.error('Error getting audio URL:', error);
        if (error.code === 'storage/object-not-found') {
          alert('The lecture audio does not exist yet. Try generating the lecture first.');
        } else {
          alert('Error loading audio. Please try again.');
        }
      }
    } else {
      alert(isTopic ? "No topic lecture available." : "No lecture available for this day.");
    }
  };

  const handleAssessmentSelectionChange = (selection, structuredContent) => {
    setSelectedAssessmentItems(selection);
    setSelectedContent(structuredContent);
  };

  // Move fetchQuizResults outside of useEffect but inside the Course component
  const fetchQuizResults = async () => {
    try {
      const auth = getAuth();
      if (!auth.currentUser) return;

      const quizResultsRef = collection(db, 'quizResults');
      const q = query(
        quizResultsRef,
        where('userId', '==', auth.currentUser.uid),
        orderBy('timestamp', 'desc')
      );

      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setQuizResults(results);
    } catch (error) {
      console.error('Error fetching quiz results:', error);
    }
  };

  // Then in useEffect, just call the function
  useEffect(() => {
    fetchQuizResults();
  }, []);

  // Add this helper function in Course.js
  const hasSelectedContent = (selectedItems) => {
    if (!selectedItems) return false;
    
    return Object.values(selectedItems).some(topic => 
      topic && topic.content && 
      Object.values(topic.content).some(content => 
        content && content.isSelected
      )
    );
  };

  return (
    <div className="container mx-auto px-4 mt-10">
      {loading ? (
        <div className="text-center mt-10">Loading...</div>
      ) : !course ? (
        <div className="text-center mt-10">Error loading course</div>
      ) : (
        <>
          {/* Log current state before rendering */}
          {/* {console.log('Rendering with activeTab:', activeTab)} */}
          {/* {console.log('Current course state:', course)} */}
          
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-8">
            <div className="flex-grow mr-4">
              <h1 className="text-4xl font-bold text-gray-800 mb-2">{course.title}</h1>
              <p className="text-base text-gray-600 break-words">{course.description}</p>
            </div>
            <Edit2
              className="h-6 w-6 text-blue-500 cursor-pointer mt-2 sm:mt-0 flex-shrink-0"
              onClick={() => setIsEditModalOpen(true)}
            />
          </div>

          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="flex border-b">
              <button
                className={`px-4 py-2 w-1/3 ${activeTab === 'outline' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('outline')}
              >
                Outline
              </button>
              <button
                className={`px-4 py-2 w-1/3 ${activeTab === 'notes' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('notes')}
              >
                Course Notes
              </button>
              <button
                className={`px-4 py-2 w-1/3 ${activeTab === 'assessment' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setActiveTab('assessment')}
              >
                Assessment
              </button>
            </div>
            <div className="p-4">
              {activeTab === 'outline' && (
                <div>
                  <h2 className="text-2xl font-bold mb-2">Course Outline</h2>
                  {editingSummary ? (
                    <textarea
                      className="w-full p-2 border rounded"
                      value={course.outline?.summary}
                      onChange={(e) => setCourse({ ...course, outline: { ...course.outline, summary: e.target.value } })}
                      onBlur={(e) => handleSummaryEdit(e.target.value)}
                    />
                  ) : (
                    <div className="mb-4 flex items-center">
                      <p className="flex-grow">{course.outline?.summary}</p>
                      <Edit2
                        className="h-5 w-5 text-blue-500 cursor-pointer ml-2"
                        onClick={() => setEditingSummary(true)}
                      />
                    </div>
                  )}
                  <h3 className="text-xl font-semibold mb-2">Learning Objectives:</h3>
                  <ul className="list-disc pl-5 mb-4">
                    {course.outline?.objectives.map((objective, index) => (
                      <li key={index} className="mb-2 flex items-center">
                        {editingObjective === index ? (
                          <input
                            type="text"
                            className="flex-grow p-1 border rounded"
                            value={objective}
                            onChange={(e) => {
                              const updatedObjectives = [...course.outline.objectives];
                              updatedObjectives[index] = e.target.value;
                              setCourse({ ...course, outline: { ...course.outline, objectives: updatedObjectives } });
                            }}
                            onBlur={(e) => handleObjectiveEdit(index, e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                handleObjectiveEdit(index, e.target.value);
                              }
                            }}
                          />
                        ) : (
                          <>
                            <span className="flex-grow">{objective}</span>
                            <Edit2
                              className="h-5 w-5 text-blue-500 cursor-pointer ml-2"
                              onClick={() => setEditingObjective(index)}
                            />
                            <Trash2
                              className="h-5 w-5 text-red-500 cursor-pointer ml-2"
                              onClick={() => handleObjectiveDelete(index)}
                            />
                          </>
                        )}
                      </li>
                    ))}
                    <li className="mt-4 flex items-center">
                      <input
                        type="text"
                        className="flex-grow p-2 border rounded"
                        value={newObjective}
                        onChange={(e) => setNewObjective(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            handleObjectiveAdd();
                          }
                        }}
                        placeholder="New learning objective"
                      />
                      <PlusCircle
                        className="h-6 w-6 text-green-500 cursor-pointer ml-2"
                        onClick={handleObjectiveAdd}
                      />
                    </li>
                  </ul>
                </div>
              )}
              {activeTab === 'notes' && (
                <div>
                  <h2 className="text-2xl font-bold mb-4">Course Notes</h2>
                  {course.topics && Array.isArray(course.topics) ? (
                    <ul className="space-y-4">
                      {course.topics.map((topic, topicIndex) => (
                        <li key={topicIndex} className="border rounded-lg p-4">
                          <div className="flex items-center justify-between mb-2">
                            {editingTopic === topicIndex ? (
                              <input
                                type="text"
                                className="w-full p-2 border rounded"
                                value={topic.title}
                                onChange={(e) => {
                                  const updatedTopics = [...course.topics];
                                  updatedTopics[topicIndex].title = e.target.value;
                                  setCourse({ ...course, topics: updatedTopics });
                                }}
                                onBlur={(e) => handleTopicEdit(topicIndex, e.target.value)}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    handleTopicEdit(topicIndex, e.target.value);
                                  }
                                }}
                              />
                            ) : (
                              <>
                                <h3 className="text-xl font-semibold">{topic.title}</h3>
                                <div>
                                  <Edit2
                                    className="h-5 w-5 text-blue-500 cursor-pointer inline-block mr-2"
                                    onClick={() => setEditingTopic(topicIndex)}
                                  />
                                  <Trash2
                                    className="h-5 w-5 text-red-500 cursor-pointer inline-block mr-2"
                                    onClick={() => handleTopicDelete(topicIndex)}
                                  />
                                  <FileText
                                    className="h-5 w-5 text-green-500 cursor-pointer inline-block mr-2"
                                    onClick={() => handleCreateLecture(topicIndex)}
                                    title="Create Topic Lecture Summary"
                                  />
                                  <Headphones
                                    className="h-5 w-5 text-purple-500 cursor-pointer inline-block mr-2"
                                    onClick={() => readLecture(course, topicIndex)}
                                    title="Listen to Topic Lecture Summary"
                                  />
                                  {expandedTopics[topicIndex] ? (
                                    <ChevronUp
                                      className="h-5 w-5 text-gray-500 cursor-pointer inline-block"
                                      onClick={() => toggleTopicExpansion(topicIndex)}
                                    />
                                  ) : (
                                    <ChevronDown
                                      className="h-5 w-5 text-gray-500 cursor-pointer inline-block"
                                      onClick={() => toggleTopicExpansion(topicIndex)}
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                          {expandedTopics[topicIndex] && (
                            <>
                              <ul className="ml-4 space-y-2">
                                {topic.days?.map((day, dayIndex) => (
                                  <li key={dayIndex} className="border-l-2 pl-4">
                                    <div className="flex items-center justify-between">
                                      {editingDay === `${topicIndex}-${dayIndex}` ? (
                                        <input
                                          type="date"
                                          value={format(parseISO(day.date), 'yyyy-MM-dd')}
                                          onChange={(e) => handleDayEdit(topicIndex, dayIndex, e.target.value)}
                                          onBlur={() => setEditingDay(null)}
                                          className="border rounded p-1"
                                        />
                                      ) : (
                                        <div className="flex items-center">
                                          <span
                                            onClick={() => setEditingDay(`${topicIndex}-${dayIndex}`)}
                                            className="cursor-pointer hover:text-blue-500"
                                          >
                                            {format(parseISO(day.date), 'MMMM d, yyyy')}
                                          </span>
                                          <Trash2
                                            className="h-4 w-4 text-red-500 cursor-pointer ml-2 hover:text-red-700"
                                            onClick={() => handleDayDelete(topicIndex, dayIndex)}
                                          />
                                          <div className="flex items-center space-x-2">
                                            <button
                                              onClick={() => readTopic(course, topicIndex, dayIndex)}
                                              className="text-blue-600 hover:text-blue-800"
                                              title="Read Day Content"
                                            >
                                              <Volume2 className="h-4 w-4 text-blue-500 cursor-pointer ml-2 hover:text-blue-700" />
                                            </button>
                                            <button
                                              onClick={() => handleCreateLecture(topicIndex, dayIndex)}
                                              className={`text-green-600 hover:text-green-800 ${
                                                processingLecture || processingDay ? 'opacity-50 cursor-not-allowed' : ''
                                              }`}
                                              title="Create Lecture Summary"
                                              disabled={processingLecture || processingDay}
                                            >
                                              <FileText className="h-4 w-4 text-green-500 cursor-pointer ml-2 hover:text-green-700" />
                                            </button>
                                            <button
                                              onClick={() => readLecture(course, topicIndex, dayIndex)}
                                              className="text-purple-600 hover:text-purple-800"
                                              title="Listen to Lecture Summary"
                                            >
                                              <Headphones className="h-4 w-4 text-purple-500 cursor-pointer ml-2 hover:text-purple-700" />
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      <div>
                                        {expandedDays[`${topicIndex}-${dayIndex}`] ? (
                                          <ChevronUp
                                            className="h-5 w-5 text-gray-500 cursor-pointer inline-block"
                                            onClick={() => toggleDayExpansion(topicIndex, dayIndex)}
                                          />
                                        ) : (
                                          <ChevronDown
                                            className="h-5 w-5 text-gray-500 cursor-pointer inline-block"
                                            onClick={() => toggleDayExpansion(topicIndex, dayIndex)}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {expandedDays[`${topicIndex}-${dayIndex}`] && (
                                      <ul className="ml-4 mt-2 space-y-2">
                                        {day.content && day.content.map((content, contentIndex) => (
                                          <li key={contentIndex} className="flex flex-col group">
                                            {editingContent === `${topicIndex}-${dayIndex}-${contentIndex}` ? (
                                              <div className="flex flex-col space-y-2">
                                                <input
                                                  type="text"
                                                  value={editingContentTitle}
                                                  onChange={(e) => setEditingContentTitle(e.target.value)}
                                                  className="border rounded p-1"
                                                />
                                                <textarea
                                                  value={editingContentData}
                                                  onChange={(e) => setEditingContentData(e.target.value)}
                                                  className="border rounded p-1"
                                                  rows="3"
                                                />
                                                <div className="flex justify-end space-x-2">
                                                  <button
                                                    onClick={() => saveContentEdit(topicIndex, dayIndex, contentIndex)}
                                                    className="bg-blue-500 text-white px-2 py-1 rounded"
                                                  >
                                                    Save
                                                  </button>
                                                  <button
                                                    onClick={() => setEditingContent(null)}
                                                    className="bg-gray-300 px-2 py-1 rounded"
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="flex items-center mb-1">
                                                  <h4 className="text-lg font-bold flex-grow">{content.title}</h4>
                                                  <Edit2
                                                    className="h-4 w-4 text-blue-500 cursor-pointer ml-2"
                                                    onClick={() => handleContentEdit(topicIndex, dayIndex, contentIndex)}
                                                  />
                                                </div>
                                                <div className="flex items-center">
                                                  <div className="flex-grow">
                                                    {content.type === 'text' && <p>{content.data}</p>}
                                                    {content.type === 'image' && (
                                                      <div>
                                                        <p>{content.data.summary}</p>
                                                      </div>
                                                    )}
                                                    {content.type === 'audio' && (
                                                      <div>
                                                        <p>{content.data.summary}</p>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <span className="text-xs text-gray-500 ml-2">
                                                    {format(parseISO(content.timestamp), 'p')}
                                                  </span>
                                                  <div className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
                                                    <Trash2
                                                      className="h-4 w-4 text-red-500 cursor-pointer inline-block"
                                                      onClick={() => handleContentDelete(topicIndex, dayIndex, contentIndex)}
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </li>
                                        ))}
                                        {addingContentTo === `${topicIndex}-${dayIndex}` ? (
                                          <li className="flex flex-col items-start">
                                            <input
                                              type="text"
                                              value={newTitle}
                                              onChange={(e) => setNewTitle(e.target.value)}
                                              onKeyDown={(e) => handleContentKeyDown(e, topicIndex, dayIndex)}
                                              className="w-full p-2 border rounded mb-2"
                                              placeholder="Enter title"
                                            />
                                            <textarea
                                              value={newContent}
                                              onChange={(e) => setNewContent(e.target.value)}
                                              onKeyDown={(e) => handleContentKeyDown(e, topicIndex, dayIndex)}
                                              className="w-full p-2 border rounded"
                                              placeholder="Enter content (Ctrl+Enter to save, Esc to cancel)"
                                              rows="3"
                                            />
                                            <div className="flex justify-end mt-2">
                                              <Save
                                                className="h-6 w-6 text-blue-500 cursor-pointer ml-2"
                                                onClick={() => handleContentAdd(topicIndex, dayIndex)}
                                              />
                                              <X
                                                className="h-6 w-6 text-red-500 cursor-pointer ml-2"
                                                onClick={() => {
                                                  setNewTitle('');
                                                  setNewContent('');
                                                  setAddingContentTo(null);
                                                }}
                                              />
                                            </div>
                                          </li>
                                        ) : (
                                          <li className="flex space-x-2">
                                            <FileText
                                              className={`h-5 w-5 text-blue-500 cursor-pointer hover:text-blue-700 ${
                                                processingDay ? 'opacity-50 cursor-not-allowed' : ''
                                              }`}
                                              onClick={() => !processingDay && setAddingContentTo(`${topicIndex}-${dayIndex}`)}
                                            />
                                            <Image
                                              className={`h-5 w-5 text-green-500 cursor-pointer hover:text-green-700 ${
                                                processingDay ? 'opacity-50 cursor-not-allowed' : ''
                                              }`}
                                              onClick={() => !processingDay && handleImageUpload(topicIndex, dayIndex)}
                                            />
                                            <Mic
                                              className="h-5 w-5 text-red-500 cursor-pointer hover:text-red-700"
                                              onClick={() => !processingDay}
                                            />
                                            <Volume
                                              className={`h-5 w-5 text-purple-500 cursor-pointer hover:text-purple-700 ${
                                                processingDay ? 'opacity-50 cursor-not-allowed' : ''
                                              }`}
                                              onClick={() => !processingDay && handleAudioUpload(topicIndex, dayIndex)}
                                            />
                                          </li>
                                        )}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                              <Calendar
                                className="h-5 w-5 text-green-500 cursor-pointer hover:text-green-700 mt-2"
                                onClick={() => handleDayAdd(topicIndex)}
                              />
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No topics available for this course.</p>
                  )}
                  {/* Add new topic button */}
                  <div className="mt-4 flex items-center">
                    <input
                      type="text"
                      className="flex-grow p-2 border rounded"
                      value={newTopic}
                      onChange={(e) => setNewTopic(e.target.value)}
                      onKeyPress={handleTopicAdd}
                      placeholder="New topic title"
                    />
                    <PlusCircle
                      className="h-6 w-6 text-green-500 cursor-pointer ml-2"
                      onClick={() => handleTopicAdd()}
                    />
                  </div>
                </div>
              )}
              {activeTab === 'assessment' && (
                <div>
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Assessment</h2>
                    <button
                      onClick={() => setShowQuizChatbox(true)}
                      className={`${
                        !hasSelectedContent(selectedAssessmentItems)
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-green-500 hover:bg-green-600'
                      } text-white font-bold py-2 px-4 rounded transition-colors`}
                      disabled={!hasSelectedContent(selectedAssessmentItems)}
                      title={!hasSelectedContent(selectedAssessmentItems) 
                        ? 'Select content to start quiz' 
                        : 'Start quiz with selected content'}
                    >
                      Quiz Me
                    </button>
                  </div>
                  <p className="mb-4">
                    {!hasSelectedContent(selectedAssessmentItems)
                      ? 'Select topics or content items to begin a quiz.'
                      : `Selected content ready for quiz.`}
                  </p>
                  <AssessmentHierarchy 
                    topics={course.topics} 
                    onSelectionChange={handleAssessmentSelectionChange}
                    quizResults={quizResults}
                  />
                  {showQuizChatbox && (
                    <QuizChatbox 
                      onClose={() => setShowQuizChatbox(false)} 
                      selectedTopics={selectedAssessmentItems}
                      structuredContent={selectedContent}
                      onQuizComplete={fetchQuizResults}
                      courseId={courseId}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {course && (
        <EditCourseModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          course={course}
          onSave={handleCourseEdit}
        />
      )}

      {(processingDay || processingLecture) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
            <p className="text-lg font-semibold">
              {processingDay ? 'Processing your file...' : 'Generating personalized lecture...'}
            </p>
            <p className="text-sm text-gray-600 mb-4">This may take a moment. Please don't close the page.</p>
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              onClick={handleCancelUpload}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {audioPlayerUrl && (
        <AudioPlayer
          audioUrl={audioPlayerUrl}
          onClose={() => setAudioPlayerUrl(null)}
        />
      )}
    </div>
  );
}

export default Course;
