import React from 'react';

function Home() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 px-4">
      <div className="-mt-[260px]">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">Welcome to Slate Hero</h1>
        <p className="text-xl text-center text-gray-600 max-w-2xl">
          Study Well.
        </p>
      </div>
    </div>
  );
}

export default Home;
