import React, { useState, useRef, useEffect } from 'react';
import { X, Send, Mic } from 'react-feather';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';  // Import db from your firebase config
import RecordRTC from 'recordrtc';
import QuizReport from './QuizReport';

const QuizChatbox = ({ onClose, selectedTopics, structuredContent, onQuizComplete, courseId }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const hasInitialized = useRef(false);
  const messagesEndRef = useRef(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const mediaStreamRef = useRef(null);
  const isPointerDownRef = useRef(false);
  const isRecordingRef = useRef(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const functions = getFunctions();

  const [playbackAudioContext, setPlaybackAudioContext] = useState(null);
  const recordingAudioContextRef = useRef(null);

  // Add state for microphone permission
  const [micPermission, setMicPermission] = useState(null);

  const microphoneStreamRef = useRef(null);

  const mediaRecorderRef = useRef(null);

  // Initialize playback AudioContext
  useEffect(() => {
    const playbackContext = new (window.AudioContext || window.webkitAudioContext)();
    setPlaybackAudioContext(playbackContext);

    return () => {
      if (playbackContext) {
        playbackContext.close();
      }
    };
  }, []);

  const resumeAudioContext = async () => {
    if (playbackAudioContext && playbackAudioContext.state === 'suspended') {
      try {
        await playbackAudioContext.resume();
        console.log('Playback AudioContext resumed');
      } catch (err) {
        console.error('Error resuming playback AudioContext:', err);
      }
    }
  };

  const resetAudioSession = () => {
    const silentAudio = new Audio('data:audio/wav;base64,UklGRgAAAABXQVZFZm10IBAAAAABAAEAESsAACJWAAACABAAZGF0YQAAAAA=');
    silentAudio.play().then(() => {
      console.log('Audio session reset with silent audio');
    }).catch(err => {
      console.error('Error playing silent audio:', err);
    });
  };

  // Only start conversation if messages array is empty
  useEffect(() => {
    if (!hasInitialized.current && messages.length === 0) {
      hasInitialized.current = true;
      console.log('Starting initial conversation');
      const startConversation = async () => {
        console.log('Sending initial message with system prompt');
        await sendMessageToAI("Hi, I'm ready to start the quiz.", true);
      };
      startConversation();
    }
  }, []); // Empty dependency array means this runs once when component mounts

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  // Initialize AudioContext
  useEffect(() => {
    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);
    return () => {
      if (context) {
        context.close();
      }
    };
  }, []);

  const handleTTSChat = async (textToSpeak) => {
    try {
      // Resume audio context and reset session before playing
      await resumeAudioContext();
      resetAudioSession();
      console.log('Sending to TTSChatFast:', {
        text: textToSpeak
      });
      const functions = getFunctions();
      const TTSChatFast = httpsCallable(functions, 'TTSChatFast');
      const result = await TTSChatFast({ 
        text: textToSpeak, 
        voiceName: 'en-US-AvaMultilingualNeural'
      });

      if (!result.data || !result.data.audioData) {
        throw new Error('Invalid response from text-to-speech function');
      }

      const audioBlob = new Blob(
        [Uint8Array.from(atob(result.data.audioData), c => c.charCodeAt(0))],
        { type: result.data.contentType }
      );

      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      
      // Force audio to play through speaker
      audio.playsInline = true;
      audio.autoplay = true;
      
      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
        setCurrentAudio(null);
      };

      // Add a small delay before playing
      setTimeout(async () => {
        try {
          await audio.play();
          setCurrentAudio(audio);
        } catch (playError) {
          console.error('Error playing audio:', playError);
        }
      }, 500);
    } catch (error) {
      console.error('Error in TTSChat:', error);
      setCurrentAudio(null);
    }
  };

  const stopCurrentAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }
  };

  const constructSystemMessage = async (structuredContent) => {
    try {
      // Get course content from Firestore
      const courseRef = doc(db, 'courses', courseId);
      const courseDoc = await getDoc(courseRef);
      const courseData = courseDoc.data();

      // Build content string
      const contentString = Object.entries(structuredContent)
        .filter(([_, data]) => data.contents.length > 0) // Only include topics with selected content
        .map(([topicId, data]) => {
          // Find topic in course data
          const topic = courseData.topics.find(t => t.id === topicId);
          if (!topic) return '';

          // Get full content details for selected content IDs
          const selectedContent = topic.days.flatMap(day => 
            day.content.filter(content => 
              data.contents.includes(content.id)
            )
          );

          return `
            Topic: ${topic.title}
            Content Items:
            ${selectedContent.map(content => `
              Content ID: ${content.id}
              Title: ${content.title}
              Type: ${content.type}
              Data: ${content.type === 'text' ? content.data : content.data.summary}
            `).join('\n')}
          `;
        }).join('\n\n');

      const systemMessage = `
        Your role is to help me learn by asking relevant questions
        and providing brief feedback. If I answer correctly, acknowledge it briefly. 
        If I answer incorrectly, offer a short hint or encourage me to try again.
        Make sure your hints don't give away too much information. I need to think for myself!
        Important: Do not provide any answers yourself unless explicitly asked. Your goal is to properly 
        assess my understanding.

        ${contentString}

        Begin the quiz right away with the first question.
        Make sure I answer each question adequately before moving on to the next question.
        Don't acknowledge your instructions, just dive into the quiz directly!
        Once a question is answered, state the next question immediately.
      `;

      console.log('Constructed system message:', systemMessage);
      return systemMessage;
    } catch (error) {
      console.error('Error constructing system message:', error);
      throw error;
    }
  };

  const handleSend = async () => {
    if (inputText.trim()) {
      await sendMessageToAI(inputText);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const cleanupAudioResources = async () => {
    // Stop any playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }

    // Stop and cleanup microphone stream
    if (microphoneStreamRef.current) {
      microphoneStreamRef.current.getTracks().forEach(track => {
        track.stop();
        track.enabled = false;
      });
      microphoneStreamRef.current = null;
    }

    // Suspend audio context
    if (audioContext && audioContext.state !== 'closed') {
      try {
        await audioContext.suspend();
      } catch (err) {
        console.error('Error suspending audio context:', err);
      }
    }
  };

  const handleAudioStart = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPointerDown || isRecording) return;

    // Stop any currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
    }

    // Reset states before starting new recording
    setIsPointerDown(false);
    setIsRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current = null;
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => {
        track.stop();
        track.enabled = false;
      });
      mediaStreamRef.current = null;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      
      // Add audio visualization
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(stream);
      microphone.connect(analyser);
      
      // Configure analyser
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Log audio levels periodically
      const checkAudioLevel = setInterval(() => {
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b) / bufferLength;
        // console.log('Current audio level:', average);
      }, 100);

      mediaStreamRef.current = stream;
      const mediaRecorder = new MediaRecorder(stream);
      
      const audioChunks = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
          console.log('Recorded chunk size:', event.data.size);
        }
      };

      mediaRecorder.onstop = async () => {
        clearInterval(checkAudioLevel);
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm;codecs=opus' });
        console.log('Final recording size:', audioBlob.size);
        await sendAudioToGroq(audioBlob);
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsRecording(true);
      setIsPointerDown(true);

      // Add recording state logging
      mediaRecorder.onstart = () => console.log('Recording started');
      mediaRecorder.onpause = () => console.log('Recording paused');
      mediaRecorder.onresume = () => console.log('Recording resumed');
      mediaRecorder.onerror = (e) => console.error('Recording error:', e);

    } catch (err) {
      console.error('Error starting recording:', err);
      setMicPermission('denied');
      alert('Error accessing microphone. Please ensure microphone permissions are granted.');
    }
  };

  const handleAudioStop = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Reset recording states
    setIsPointerDown(false);
    setIsRecording(false);

    try {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
        
        // Clean up media stream
        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach(track => {
            track.stop();
            track.enabled = false;
          });
          mediaStreamRef.current = null;
        }

        // Reset mediaRecorder
        mediaRecorderRef.current = null;
      }

      // Reset audio context and session
      await resumeAudioContext();
      resetAudioSession();

    } catch (err) {
      console.error('Error stopping recording:', err);
      alert('Error stopping recording. Please try again.');
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        resolve(base64data);
      };
      reader.readAsDataURL(blob);
    });
  };

  const sendAudioToGroq = async (audioBlob) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const base64Audio = await blobToBase64(audioBlob);
      console.log('Base64 audio length:', base64Audio.length);

      const transcribeAudioGroq = httpsCallable(functions, 'transcribeAudioGroq');
      
      const result = await transcribeAudioGroq({ 
        audioData: base64Audio,
        mimeType: audioBlob.type
      });

      console.log('Transcription result:', result.data);

      const transcription = result.data.transcription;
      if (transcription) {
        console.log('Successful transcription:', transcription);
        await sendMessageToAI(transcription);
      } else {
        throw new Error('Transcription failed or returned empty.');
      }
    } catch (error) {
      console.error('Error in speech-to-text:', error);
      if (error.code) console.error('Error code:', error.code);
      if (error.details) console.error('Error details:', error.details);
      setMessages(prev => [...prev, { 
        text: 'Error transcribing audio. Please try again.', 
        sender: 'system' 
      }]);
    }
  };

  const sendMessageToAI = async (message, isInitial = false) => {
    if (!isInitial) {
      const userMessage = { text: message, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, userMessage]);
    } else {
      // first user message is the tutor instructions
      message = await constructSystemMessage(structuredContent);
    }
    setInputText('');
    setIsLoading(true);

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const functions = getFunctions();
      const chatWithAI = httpsCallable(functions, 'chatWithAI');

      // Prepare message history
      const messageHistory = messages.map(msg => ({
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text
      }));

      // Add current message to history
      messageHistory.push({
        role: 'user',
        content: message
      });

      console.log('Sending to chatWithAI:', {
        message,
        history: messageHistory,
        isInitial,
        selectedTopics
      });

      const result = await chatWithAI({
        message: message,
        history: messageHistory,
        systemMessage: 'You are an expert tutor named Slate.',
        isInitial: isInitial,
        selectedTopics: selectedTopics,
        sessionId: isInitial ? null : sessionId,
        courseId: courseId
      });

      console.log('Response from chatWithAI:', result);

      if (!result.data || typeof result.data.text !== 'string') {
        throw new Error('Invalid response format from AI');
      }

      // Store sessionId from initial message
      if (isInitial && result.data.sessionId) {
        setSessionId(result.data.sessionId);
      }

      const botMessage = { text: result.data.text, sender: 'bot' };
      setMessages(prevMessages => [...prevMessages, botMessage]);

      // Send to TTS
      handleTTSChat(result.data.text);
    } catch (error) {
      console.error('Error in chat:', error);
      console.error('Full error details:', error.response?.data || error);
      setMessages(prevMessages => [...prevMessages, { 
        text: 'Sorry, an error occurred. Please try again.',
        sender: 'bot'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Add new state for showing report
  const [showReport, setShowReport] = useState(false);

  // Add state to control quiz chat visibility
  const [showChat, setShowChat] = useState(true);

  // Modify handleClose to hide chat when showing report
  const handleClose = async () => {
    if (!sessionId) {
      console.error('No session ID available');
      alert('Error: Quiz session not properly initialized');
      return;
    }

    stopCurrentAudio();
    setIsLoading(true);
    
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const functions = getFunctions();
      const generateQuizReport = httpsCallable(functions, 'handleClaudeAssess');
      
      const result = await generateQuizReport({
        messages: messages,
        systemMessage: constructSystemMessageAssess(structuredContent, messages),
        sessionId: sessionId
      });

      if (!result.data || !result.data.report) {
        throw new Error('Invalid report response from AI');
      }

      // Hide chat and show report with the generated content
      setShowChat(false);
      setShowReport(true);
      // We'll modify QuizReport to accept and display the AI-generated report
      setReportContent(result.data.report);
      
      // Call the onQuizComplete callback to refresh quiz results
      if (onQuizComplete) {
        await onQuizComplete();
      }
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Error generating quiz report. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Add new function to handle final close after report
  const handleFinalClose = () => {
    setShowReport(false);
    setShowChat(true); // Reset for next time
    onClose();
  };

  // Clean up audio when component unmounts
  useEffect(() => {
    return () => {
      cleanupAudioResources();
    };
  }, []);

  // Detect device type on component mount
  useEffect(() => {
    const checkDevice = () => {
      // Check if device is mobile
      const mobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
      setIsMobile(mobile);
      
      // Check specifically for iOS
      const ios = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      setIsIOS(ios);
    };

    checkDevice();
  }, []);

  // Modified audio handlers for mobile
  const handleMobileAudioToggle = async () => {
    if (isRecording) {
      // Stop recording
      try {
        if (!recorder) {
          console.error('No recorder found');
          return;
        }

        recorder.stopRecording(async () => {
          const blob = recorder.getBlob();
          
          // Clean up recorder
          recorder.destroy();
          setRecorder(null);

          // Stop all tracks
          if (mediaStreamRef.current) {
            mediaStreamRef.current.getTracks().forEach(track => {
              track.stop();
              track.enabled = false;
            });
            mediaStreamRef.current = null;
          }

          // Send audio for transcription
          await sendAudioToGroq(blob);
        });

        setIsRecording(false);
      } catch (err) {
        console.error('Error stopping recording:', err);
        alert('Error stopping recording. Please try again.');
      }
    } else {
      // Start recording
      try {
        // Request permissions explicitly
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          }
        });

        mediaStreamRef.current = stream;
        
        const recordRTC = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          sampleRate: 44100,
          desiredSampRate: 16000,
          recorderType: RecordRTC.StereoAudioRecorder,
          numberOfAudioChannels: 1,
          timeSlice: 1000, // Get data every second
          ondataavailable: (blob) => {
            console.log('Recording data available:', blob.size);
          }
        });

        recordRTC.startRecording();
        setRecorder(recordRTC);
        setIsRecording(true);
      } catch (err) {
        console.error('Error accessing microphone:', err);
        if (err.name === 'NotAllowedError') {
          alert('Microphone permission denied. Please enable microphone access to record audio.');
        } else {
          alert('Error accessing microphone. Please ensure your device has a working microphone.');
        }
      }
    }
  };

  // Add a function to request and verify microphone permissions
  const requestMicrophonePermission = async () => {
    try {
      // First check if permissions are already granted
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      
      if (permissionStatus.state === 'granted') {
        setMicPermission('granted');
        return true;
      }

      // If not granted, request access
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });

      // Stop the stream immediately - we just needed it for permission
      stream.getTracks().forEach(track => track.stop());
      
      setMicPermission('granted');
      return true;
    } catch (err) {
      console.error('Error requesting microphone permission:', err);
      setMicPermission('denied');
      
      // Show a more helpful message for mobile users
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        alert('Please allow microphone access in your browser settings to use voice input. You may need to refresh the page after enabling permissions.');
      } else {
        alert('Microphone access was denied. Please enable it in your browser settings.');
      }
      return false;
    }
  };

  // Add useEffect to check permissions on mount
  useEffect(() => {
    requestMicrophonePermission();
    
    // Cleanup function
    return () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  // Add visual feedback for microphone status
  const renderMicButton = () => {
    if (micPermission === 'granted') {
      return (
        <div
          role="button"
          tabIndex={0}
          onPointerDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAudioStart(e);
          }}
          onPointerUp={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAudioStop(e);
          }}
          onPointerLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAudioStop(e);
          }}
          onPointerCancel={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAudioStop(e);
          }}
          className={`p-2 rounded cursor-pointer select-none touch-none ${
            isRecording ? 'bg-red-500' : 'bg-blue-500'
          } text-white hover:opacity-80 ${
            isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          aria-label="Record audio"
          aria-pressed={isRecording}
          style={{ touchAction: 'none' }} // Prevent browser handling of touch events
        >
          <Mic size={20} />
        </div>
      );
    } else if (micPermission === 'denied') {
      return (
        <div 
          className="p-2 rounded bg-gray-400 text-white cursor-not-allowed flex items-center"
          title="Microphone access denied"
        >
          <Mic size={20} />
          <span className="ml-2">Mic disabled</span>
        </div>
      );
    } else {
      return (
        <div className="p-2 rounded bg-gray-300 text-white">
          <Mic size={20} />
        </div>
      );
    }
  };

  // Add this useEffect to handle microphone permissions
  useEffect(() => {
    const initializeMicrophone = async () => {
      try {
        // Get initial microphone access
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          }
        });
        
        // Store the stream reference
        mediaStreamRef.current = stream;
        setMicPermission('granted');
        
        // Stop the initial stream tracks
        stream.getTracks().forEach(track => track.stop());
      } catch (err) {
        console.error('Error initializing microphone:', err);
        setMicPermission('denied');
        if (err.name === 'NotAllowedError') {
          alert('Please allow microphone access to use voice input.');
        }
      }
    };

    initializeMicrophone();

    // Cleanup function
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const constructSystemMessageAssess = (structuredContent, messages) => {
    const systemMessage = `
      You are an expert educational assessor. Analyze the following quiz conversation 
      and provide a structured assessment with scores.

      Content covered in quiz:
      ${Object.entries(structuredContent).map(([topicId, data]) => {
        return `
          Topic: ${topicId}
          Content Items:
          ${data.contents.map(contentId => `
            Content ID: ${contentId}
          `).join('\n')}
        `;
      }).join('\n\n')}

      Quiz Conversation:
      ${messages.map(msg => `${msg.sender === 'user' ? 'Student' : 'Tutor'}: ${msg.text}`).join('\n')}

      Provide your assessment in this exact JSON format:
      {
        "contentScores": {
          "[exact_content_id]": {
            "score": number(1-3),
            "title": "exact content title",
            "reason": "brief explanation"
          }
        },
        "report": {
          "overall": "text",
          "byTopic": {
            "[topic_name]": "text"
          },
          "strengths": ["point1", "point2"],
          "improvements": ["point1", "point2"],
          "recommendations": ["point1", "point2"]
        }
      }

      Important: Use the exact Content ID values provided above. Each assessed content must have a matching ID.
    `;

    return systemMessage;
  };

  // Add this with the other state declarations at the top of the component
  const [reportContent, setReportContent] = useState(null);

  return (
    <>
      {/* Only show chat overlay and interface if showChat is true */}
      {showChat && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-xl w-[32rem] h-[36rem] flex flex-col">
              {/* Header */}
              <div className="flex justify-between items-center p-4 border-b">
                <h3 className="text-lg font-semibold">Quiz Session</h3>
                <button 
                  onClick={handleClose} 
                  className="text-gray-500 hover:text-gray-700"
                  title="Close quiz session"
                >
                  <X size={20} />
                </button>
              </div>

              {/* Messages container */}
              <div className="flex-1 overflow-y-auto p-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-4 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}
                  >
                    <div
                      className={`inline-block p-2 rounded-lg ${
                        message.sender === 'user'
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-800'
                      }`}
                    >
                      {message.text}
                    </div>
                  </div>
                ))}
                {isLoading && <div className="text-center">Thinking...</div>}
                <div ref={messagesEndRef} />
              </div>

              {/* Input area */}
              <div className="border-t p-4">
                <div className="flex items-center space-x-2">
                  <textarea
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your answer..."
                    className="flex-1 p-2 border rounded-l resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows="2"
                    disabled={isLoading}
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={handleSend}
                      className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-blue-300"
                      disabled={isLoading}
                    >
                      <Send size={20} />
                    </button>
                    {renderMicButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Show report modal when showReport is true */}
      {showReport && (
        <QuizReport 
          onClose={handleFinalClose}
          structuredContent={structuredContent}
          reportContent={reportContent}
        />
      )}
    </>
  );
};

export default QuizChatbox;
