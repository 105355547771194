import React from 'react';
import { X } from 'react-feather';

const QuizReport = ({ onClose, structuredContent, reportContent }) => {
  const renderReport = () => {
    if (!reportContent) return null;
    
    try {
      const report = typeof reportContent === 'string' 
        ? JSON.parse(reportContent) 
        : reportContent;

      return (
        <div className="space-y-4">
          <div>
            <h4 className="font-semibold text-lg mb-2">Overall Assessment</h4>
            <p>{report.overall}</p>
          </div>

          <div>
            <h4 className="font-semibold text-lg mb-2">Topic Breakdown</h4>
            {Object.entries(report.byTopic).map(([topic, assessment], index) => (
              <div key={`topic-breakdown-${topic}-${index}`} className="mb-3">
                <h5 className="font-medium text-gray-700">{topic}</h5>
                <p>{assessment}</p>
              </div>
            ))}
          </div>

          <div>
            <h4 className="font-semibold text-lg mb-2">Strengths</h4>
            <ul className="list-disc pl-5">
              {report.strengths.map((strength, index) => (
                <li key={`strength-${index}-${strength.slice(0, 10)}`}>{strength}</li>
              ))}
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-lg mb-2">Areas for Improvement</h4>
            <ul className="list-disc pl-5">
              {report.improvements.map((improvement, index) => (
                <li key={`improvement-${index}-${improvement.slice(0, 10)}`}>{improvement}</li>
              ))}
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-lg mb-2">Recommendations</h4>
            <ul className="list-disc pl-5">
              {report.recommendations.map((recommendation, index) => (
                <li key={`recommendation-${index}-${recommendation.slice(0, 10)}`}>{recommendation}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    } catch (error) {
      console.error('Error rendering report:', error);
      return <div className="text-red-500">Error displaying report</div>;
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
      
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl w-[48rem] max-h-[36rem] flex flex-col">
          <div className="flex justify-between items-center p-4 border-b">
            <h3 className="text-lg font-semibold">Quiz Assessment Report</h3>
            <button 
              onClick={onClose} 
              className="text-gray-500 hover:text-gray-700"
              title="Close report"
            >
              <X size={20} />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {reportContent ? (
              renderReport()
            ) : (
              <div className="text-center text-gray-600">
                <p>Generating report...</p>
              </div>
            )}
            
            <div className="mt-6">
              <h4 className="text-md font-semibold mb-3">Content Covered:</h4>
              {Object.entries(structuredContent).map(([topicId, data]) => (
                <div key={topicId} className="mb-4">
                  <h5 className="font-medium text-gray-700 mb-2">{topicId}</h5>
                  <ul className="list-disc pl-5">
                    {data.contents.map((contentId) => (
                      <li key={contentId} className="text-gray-600 mb-1">
                        Content ID: {contentId}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizReport; 